export const RATING_NAME = {
  checkin_process: "Checkin Process",
  communication: "Communication",
  compliance: "Compliance",
  profesionalism: "Profesionalism",
  service_quality: "Service Quality",
} as any;

export const LISTING_STATUS_NAME = {
  draft: "Draft",
  listed: "Listed",
  unlisted: "Unlisted",
} as any;

export const SUBSCRIPTION_STAGE_NAME = {
  active: "Active",
  pending_payment: "Pending Payment",
  inactive: "Inactive",
} as any;

export const LISTING_STATUS_USER = {
  approved: "Approved",
  pending: "Pending",
  rejected: "Rejected",
} as any;

export const LISTING_TYPE = {
  SCIENTIFIC_PROCESS: "scientific_process",
  EQUIPMENT: "equipment",
  SERVICE: "service",
} as any;

export const LISTING_TYPE_NAME = {
  scientific_process: "Scientific Process",
  equipment: "Equipment",
  service: "Service",
} as any;
export const LISTING_PROCESS_STATUS = {
  listed: "Active",
  unlisted: "Inactive",
  draft: "Draft",
} as any;

export function getListingTypeName(type: string) {
  return LISTING_TYPE_NAME[type];
}

export const WORKING_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const defaultWorkingHours = [
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: true, start_time: "800", end_time: "1700" },
  { open: false, start_time: "800", end_time: "1700" },
  { open: false, start_time: "800", end_time: "1700" },
];
export const defaultPagination = {
  page: 0,
  limit: 10,
};

export const supportedBrowsers: Record<string, number> = {
  Chrome: 90,
  Firefox: 80,
  Safari: 13,
  Edge: 90,
  Opera: 70,
};
